<template>
  <page-layout title="商品详情" :goback="true">
    <div slot="action" style="position: absolute; top: 16px; right: 0">
      <div class="buy_cart" @click="$router.push({ path: 'buyCart' })">
        购物车<a-badge
          :count="cartNum"
          :number-style="{
            backgroundColor: 'transparent',
            color: '#3ab887',
            boxShadow: '0 0 0 1px #3ab887 inset',
            marginLeft: '9px',
            top: '-1px',
          }"
        />
      </div>
    </div>
    <a-skeleton
      style="background-color: #fff; padding: 24px"
      v-if="detailSpinning"
      active
      :loading="detailSpinning"
      :paragraph="{ rows: 5 }"
    />
    <div id="goodsDetail" v-if="!detailSpinning">
      <div class="up">
        <div class="up_left">
          <div class="list-box">
            <div class="left_box">
              <div class="pr">
                <img
                  src="@/assets/img/play.png"
                  class="play"
                  alt=""
                  v-if="storeInfo.video_link"
                  @click="showVideo"
                />
                <a-carousel
                  class="img"
                  autoplay
                  arrows
                  v-if="
                    storeInfo.slider_image && storeInfo.slider_image.length > 0
                  "
                >
                  <div
                    slot="prevArrow"
                    class="custom-slick-arrow"
                    style="left: 10px; z-index: 1"
                  >
                    <a-icon type="left-circle" />
                  </div>
                  <div
                    slot="nextArrow"
                    class="custom-slick-arrow"
                    style="right: 10px"
                  >
                    <a-icon type="right-circle" />
                  </div>
                  <!-- <video :src="storeInfo.video_link" class="video" v-if="storeInfo.video_link"></video> -->
                  <div
                    style="width: 497px"
                    v-for="(item, i) in storeInfo.slider_image"
                    :key="i"
                  >
                    <img style="object-fit: contain" :src="item" alt="" />
                  </div>
                </a-carousel>
              </div>
            </div>

            <div class="content_box">
              <div class="title line2">{{ storeInfo.store_name }}</div>
              <div class="label_box">
                <span class="yy" v-if="storeInfo.is_oversold == 1">
                  预售商品
                </span>
                <span class="yy" v-if="storeInfo.is_purchase == 1">
                  限购{{ storeInfo.purchase_limit }}件
                </span>
                <span
                  class="label"
                  v-for="(item, index) in storeInfo.label_name"
                  :key="index"
                  :style="
                    'color:' +
                    labelColor[item.color - 1].color +
                    ';border-color:' +
                    labelColor[item.color - 1].color
                  "
                >
                  {{ item.label_name }}
                </span>
              </div>
              <div class="title1">{{ storeInfo.store_info }}</div>
              <div class="total_box" v-if="storeInfo.good_type == 2">
                <img src="@/assets/img/total_icon.png" alt="" />
                <div>
                  包含{{ storeInfo.current_number
                  }}{{ storeInfo.genre == 1 ? "本书" : "件物资" }} /
                  {{ storeInfo.kind }}种类目
                </div>
              </div>
              <div class="book_situation">
                <!-- <a-icon type="environment" theme="filled" /> -->
                <img src="@/assets/img/address_icon.png" alt="" />
                <div>
                  <div v-if="address.toString()">
                    配送至&nbsp;&nbsp;{{ address.province }}{{ address.city
                    }}{{ address.district
                    }}{{ address.detail }}&nbsp;&nbsp;<span
                      class="green"
                      style="cursor: pointer"
                      @click="edit()"
                    >
                      修改
                    </span>
                  </div>
                  <div v-else>
                    暂无收货地址
                    <span class="green" style="cursor: pointer" @click="edit()">
                      添加
                    </span>
                  </div>
                  <div class="green mart5">
                    <div>
                      <span>库存:{{ stock | stock }}</span>
                    </div>
                    <div>
                      商品分类:
                      <span v-for="item in storeInfo.cate_name" :key="item">{{
                        item
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sku_box">
                <div>选择规格</div>
                <div class="button_box">
                  <a-button
                    class="button"
                    :class="
                      selected == item.unique ? 'selected button' : 'button'
                    "
                    value="small"
                    v-for="item in productValue"
                    :key="item.id"
                    :disabled="item.is_sale == 0"
                    @click="
                      (selected = item.unique),
                        (skuData = item),
                        (stock = item.stock)
                    "
                  >
                    {{ item.suk }}
                  </a-button>
                </div>
                <div class="selected" v-if="skuData.suk">
                  <span>已选: {{ skuData.suk }}</span>
                  <span style="margin-left: 10px">
                    库存: {{ stock | stock }}
                  </span>
                </div>
              </div>
              <div class="money_box" v-if="skuData.suk">
                <span class="current">{{ skuData.price | price }}元</span>
                <del class="original"> ￥{{ skuData.ot_price | price }} </del>
                <!-- <span class="original">
                  &nbsp;/&nbsp;已售{{ skuData.sales }}
                </span> -->
              </div>
              <div class="button_box1">
                <a-button
                  :disabled="!skuData.suk"
                  type="primary"
                  class="buy"
                  @click="addCart(1)"
                >
                  立即购买
                </a-button>
                <a-button
                  v-if="
                    (storeInfo.good_type == 1 || storeInfo.good_type == 2) &&
                    storeInfo.is_cust == 0 &&
                    storeInfo.pay_type_info.is_dandu == 0
                  "
                  style="margin-right: 16px"
                  :disabled="storeInfo.is_oversold != 1 && !stock"
                  @click="addCart(0)"
                >
                  加入购物车
                </a-button>
                <div
                  :class="
                    storeInfo.userCollect == true ? 'heart_box' : 'no_heart_box'
                  "
                  @click="collect(storeInfo.userCollect)"
                >
                  <a-icon type="heart" theme="filled" />
                  <span>商品收藏</span>
                </div>
              </div>
            </div>
          </div>
          <div class="item_detail">
            <div class="detail1" :style="selectTab == 1 ? 'width:100%' : ''">
              <div class="detail_tab bottom" v-if="storeInfo.good_type == 1">
                <div
                  v-for="(item, index) in tabList"
                  :key="index"
                  :class="selectTab == index ? 'select_tab' : ''"
                  @click="changeTab(index)"
                >
                  {{ item }}
                </div>
              </div>
              <div
                v-if="storeInfo.good_type != 1"
                class="bottom type2"
                style="padding-bottom: 6px"
              >
                <div class="detail_tab">
                  <div
                    v-for="(item, index) in tabList2"
                    :key="index"
                    :class="selectTab == index ? 'select_tab' : ''"
                  >
                    {{ item }}
                  </div>
                </div>
                <div class="title" v-if="storeInfo.good_type != 1">
                  <!-- <a-select
                    style="width: 186px; margin-right: 24px"
                    v-model="cateId"
                    @change="getMeal()"
                  >
                    <a-select-option value="">筛选商品分类</a-select-option>
                  </a-select> -->
                  <div class="header-search">
                    <a-input-search
                      placeholder="请输入商品名称搜索"
                      v-model="keywords"
                      @search="getMeal()"
                      style="width: 188px"
                    />
                    <!-- <span class="search" @click="getMeal()"><a-icon type="search" class="search-icon"/></span>        -->
                  </div>
                </div>
              </div>
              <div class="html" v-if="storeInfo.description && selectTab == 0">
                <div v-html="storeInfo.description"></div>
              </div>
              <div
                class="no_detail"
                v-if="
                  (storeInfo.good_type == 1 && !storeInfo.description) ||
                  (storeInfo.good_type == 2 && data.length == 0)
                "
              >
                <img src="@/assets/img/no_detail.png" alt="" />
                <span>暂无详情~</span>
              </div>
              <div class="content" v-if="storeInfo.good_type != 1">
                <a-table
                  ref="table"
                  :columns="columns"
                  :data-source="data"
                  rowKey="id"
                  :pagination="pagination"
                  :scroll="{ y: 800 }"
                  style="margin-top: 24px"
                  :loading="loading"
                >
                  <div slot="key" slot-scope="text, record, index">
                    {{ index + 1 }}
                  </div>
                  <template slot="goods" slot-scope="text, record">
                    <a-popover title="商品名称">
                      <template #content>
                        <p>{{ record.store_name || record.name }}</p>
                      </template>
                      <div class="product_info">
                        <img
                          :src="record.image"
                          v-if="record.image"
                          alt=""
                          class="product_img"
                        />
                        <span class="new_line2">{{
                          record.store_name || record.name
                        }}</span>
                      </div>
                    </a-popover>
                  </template>
                  <div slot="number" slot-scope="text, record">
                    <span>{{ record.number || record.num }}</span>
                  </div>
                </a-table>
              </div>
            </div>
            <div
              class="detail2"
              v-if="selectTab == 0 && storeInfo.good_type == 1"
            >
              <div class="detail1_title">档案信息</div>
              <ul v-if="storeInfo.genre == 1">
                <li>
                  <div class="li_l">书籍名称</div>
                  <div class="li_r">{{ storeInfo.store_name }}</div>
                </li>
                <li>
                  <div class="li_l">ISBN编号</div>
                  <div class="li_r">{{ skuData.isbn }}</div>
                </li>
                <li>
                  <div class="li_l">书籍分类</div>
                  <div class="li_r">
                    <span
                      v-for="(item, index) in storeInfo.cate_name"
                      :key="index"
                      style="margin-right: 6px"
                      >{{ item }}</span
                    >
                  </div>
                </li>
                <li>
                  <div class="li_l">出版社</div>
                  <div class="li_r">{{ storeInfo.publishing_house }}</div>
                </li>

                <li>
                  <div class="li_l">出版时间</div>
                  <div class="li_r">{{ storeInfo.publication_time }}</div>
                </li>
                <li>
                  <div class="li_l">作者</div>
                  <div class="li_r">{{ storeInfo.author }}</div>
                </li>
                <li>
                  <div class="li_l">装订方式</div>
                  <div class="li_r">{{ storeInfo.packaging }}</div>
                </li>
                <li>
                  <div class="li_l">开本</div>
                  <div class="li_r">{{ storeInfo.format }}</div>
                </li>
                <li>
                  <div class="li_l">总页数</div>
                  <div class="li_r">{{ storeInfo.paper_limie }}</div>
                </li>
              </ul>
              <ul v-if="storeInfo.genre == 2">
                <li>
                  <div class="li_l">物资名称</div>
                  <div class="li_r">{{ storeInfo.store_name }}</div>
                </li>
                <li>
                  <div class="li_l">分类</div>
                  <div class="li_r">
                    <span
                      v-for="(item, index) in storeInfo.cate_name"
                      :key="index"
                      style="margin-right: 6px"
                    >
                      {{ item }}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <div
              class="detail2"
              v-if="selectTab == 0 && storeInfo.good_type != 1"
            >
              <div class="detail1_title">套餐信息</div>
              <ul>
                <li>
                  <div class="li_l">套餐名称</div>
                  <div class="li_r">{{ storeInfo.store_name }}</div>
                </li>
                <li>
                  <div class="li_l">套餐分类</div>
                  <div class="li_r">
                    <span
                      v-for="(item, index) in storeInfo.cate_name"
                      :key="index"
                      style="margin-right: 6px"
                      >{{ item }}</span
                    >
                  </div>
                </li>
                <li>
                  <div class="li_l">
                    包含{{ storeInfo.genre == 1 ? "书籍" : "物资" }}
                  </div>
                  <div class="li_r">{{ storeInfo.current_number }}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="up_right" v-if="isShowCart">
          <shopping-cart ref="rightCart"></shopping-cart>
          <!-- <div class="top">
              <img src="@/assets/img/hot.svg" alt="" />
              <span>图书热卖</span>
            </div>
            <div class="bottom">
              <div class="bottom_content" v-for="item in hot" :key="item.productId" @click="$router.push({
                path: 'goodsDetail',
                query: { id: item.productId },
              })">
                <img :src="item.image" alt="" />
                <div style="width: 66%">
                  <div class="book_name">{{ item.storeName }}</div>
                  <div class="book_money">{{ item.price }}元</div>
                </div>
              </div>
            </div>
            <div class="see_more" @click="
              $router.push({
                path: 'mealGoods',
                query: { type: 1, cateId: 0, level: 1 },
              })
            ">
              查看更多
            </div> -->
        </div>
      </div>
      <!-- </a-spin> -->
      <a-modal
        title="修改地址"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="onSubmit"
        @cancel="handleCancel"
        :width="500"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="收件人" prop="real_name">
            <a-input v-model="form.real_name" placeholder="请输入收件人" />
          </a-form-model-item>
          <a-form-model-item label="联系方式" prop="phone">
            <a-input v-model="form.phone" placeholder="请输入联系方式" />
          </a-form-model-item>
          <a-form-model-item label="所在地区" class="ssq">
            <a-cascader
              :field-names="{ label: 'n', value: 'v', children: 'c' }"
              :options="options"
              :default-value="value"
              placeholder="选择省市区"
              @change="onChange"
            >
              <template
                slot="displayRender"
                slot-scope="{ labels, selectedOptions }"
              >
                <span
                  v-for="(label, index) in labels"
                  :key="selectedOptions[index].value"
                >
                  <span> {{ label }} / </span>
                </span>
              </template>
            </a-cascader>
          </a-form-model-item>
          <a-form-model-item label="详细地址" prop="detail">
            <a-input v-model="form.detail" placeholder="请输入详细地址" />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <a-modal
        :visible="videoBox"
        title="书本视频"
        :footer="null"
        :maskClosable="true"
        @cancel="cancel"
      >
        <video
          :src="storeInfo.video_link"
          controls="controls"
          class="video_box"
        >
          您的浏览器不支持 video 标签。
        </video>
      </a-modal>
    </div>
    <shopping-cart-fixed
      class="fixed"
      ref="shoppingCart"
      @getCartNum="getCartNum"
      v-if="!isShowCart"
    />
    <a-modal
      :visible="numberBox"
      :width="360"
      title="立即购买数量"
      @cancel="cancelBuy"
      @ok="goBuy"
    >
      <a-form-model :model="formNumber" layout="inline">
        <a-form-model-item label="购买数量">
          <a-input-number
            id="inputNumber"
            v-model="formNumber.num"
            :min="minNumber"
            :max="storeInfo.is_purchase == 1 ? storeInfo.purchase_limit : 9999"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-layout>
</template>

<script>
import shoppingCartFixed from "@/components/shoppingCart/index-fixed";
import shoppingCart from "@/components/shoppingCart/index";
import PageLayout from "@/layouts/PageLayout";
import { throttle } from "@/utils/throttle";
import { addCollect, delCollect } from "@/api/collection";
import { labelColor } from "@/utils/color";
import {
  addCart,
  cartCount,
  detail,
  binningProductList,
  singleProductList,
} from "@/api/allGoods";
import { addressList, addAddress, cityList } from "@/api/receivingAdress";
import { price } from "@/utils/filters";

const columns = [
  {
    title: "序号",
    dataIndex: "key",
    key: "key",
    align: "center",
    scopedSlots: { customRender: "key" },
  },
  {
    title: "商品信息",
    dataIndex: "goods",
    key: "goods",
    scopedSlots: { customRender: "goods" },
    // ellipsis: true,
    align: "left",
    width: 200,
  },
  {
    title: "商品数量",
    dataIndex: "number",
    scopedSlots: { customRender: "number" },
    key: "number",
    align: "center",
  },
  {
    title: "ISBN",
    dataIndex: "isbn",
    key: "isbn",
    ellipsis: true,
    align: "center",
    width: 150,
  },
  {
    title: "商品编号",
    dataIndex: "caseNumber",
    key: "caseNumber",
    customRender: (text, record) => {
      return text || record.bar_code;
    },
    align: "center",
  },
];

export default {
  name: "goodsDetail",
  components: { PageLayout, shoppingCartFixed, shoppingCart },
  data() {
    return {
      loading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      selected: "",
      visible: false,
      tab: 1,
      labelColor,
      list: [
        {
          title: "作者",
          title1: "黄朴民 ",
          img: require("../../../assets/img/icon1.svg"),
        },
        {
          title: "出版社",
          title1: "安徽文艺出版社",
          img: require("../../../assets/img/icon2.svg"),
        },
        {
          title: "出版时间",
          title1: "2021年11月",
          img: require("../../../assets/img/icon3.svg"),
        },
        {
          title: "所属分类",
          title1: "图书/传统文化",
          img: require("../../../assets/img/icon4.svg"),
        },
        {
          title: "是否套装",
          title1: "是",
          img: require("../../../assets/img/icon5.svg"),
        },
        {
          title: "ISBN编号",
          title1: "978-7-5076-0334-7",
          img: require("../../../assets/img/icon6.svg"),
        },
        {
          title: "开本",
          title1: "32开 ",
          img: require("../../../assets/img/icon7.svg"),
        },
        {
          title: "纸张",
          title1: "胶版纸",
          img: require("../../../assets/img/icon8.svg"),
        },
        {
          title: "包装",
          title1: "平装-胶订",
          img: require("../../../assets/img/icon9.svg"),
        },
      ],
      data: [],
      columns,
      cartNum: "",
      uniqueId: "",
      addressId: 0,
      id: "",
      storeInfo: {},
      productValue: [],
      options: [],
      uid: "",
      hot: [],
      skuData: {},
      cateId: "",
      keywords: "",
      address: [],
      confirmLoading: false,
      form: {
        id: 0,
        real_name: "",
        phone: "",
        province: "",
        city: "",
        district: "",
        city_id: "",
        district_id: "",
        detail: "",
        isDefault: "",
      },
      rules: {
        real_name: [{ required: true, message: "请填写姓名", trigger: "blur" }],
        phone: [{ required: true, message: "请填写电话", trigger: "blur" }],
        detail: [
          { required: true, message: "请填写详细地址", trigger: "blur" },
        ],
      },
      value: undefined,
      tabList: ["详情信息"],
      tabList2: ["包含内容"],
      selectTab: 0,
      videoBox: false,
      detailSpinning: false,
      good_type: 0,
      isShowCart: false,
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        total: 0,
      },
      numberBox: false,
      formNumber: {
        num: 1,
      },
      stock: 0,
      minNumber: 1,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getData();
    this.getAdress();
    this.getCartNum();
  },
  computed: {},
  destroyed() {
    window.removeEventListener("resize", this.setShow);
  },
  mounted() {
    this.isShowCart = document.body.offsetWidth >= 1600;
    window.addEventListener("resize", this.setShow);
  },
  filters: {
    price,
    stock(val) {
      let nVal = Number(val);
      return nVal < 0 ? 0 : nVal;
    },
  },
  watch: {},
  methods: {
    cancelBuy() {
      this.numberBox = false;
    },
    goBuy() {
      if (!this.formNumber.num) {
        this.$message.warning("请输入购买数量");
        return false;
      }
      let parmas = {
        productId: this.storeInfo.id,
        cartNum: this.formNumber.num,
        uniqueId: this.selected,
        is_new: 1,
      };
      addCart(parmas).then((res) => {
        this.$router.push({
          path: "submitOrder",
          query: { cartId: res.data.data.cartId, new: 1 },
        });
      });
    },
    setShow() {
      this.isShowCart = document.body.offsetWidth >= 1600;
    },
    cancel() {
      this.videoBox = false;
    },
    showVideo() {
      this.videoBox = true;
    },
    getCartNum() {
      cartCount().then((res) => {
        this.cartNum = res.data.data.count;
      });
    },
    getAdress() {
      cityList().then((res) => {
        this.options = res.data.data;
      });
    },
    getData() {
      this.detailSpinning = true;
      detail(this.id)
        .then((res) => {
          this.storeInfo = res.data.data.storeInfo;
          this.productValue = res.data.data.productValue;
          this.minNumber = res.data.data.storeInfo.minimum_quantity;
          this.formNumber.num = res.data.data.storeInfo.minimum_quantity;
          this.uid = res.data.data.uid;
          const arr = this.productValue.filter((item) => item.is_sale == 1);
          if (arr.length > 0) {
            this.selected = arr[0].unique;
            this.skuData = arr[0];
            this.stock = arr[0].stock || 0;
          } else {
            this.selected = undefined;
            this.skuData = {};
            this.stock = 0;
          }
          // this.selected = this.productValue[0].unique;
          // this.skuData = this.productValue[0];
          // this.stock = this.productValue[0].stock || 0;
          this.list[0].title1 = this.storeInfo.author;
          this.list[1].title1 = this.storeInfo.publishing_house;
          this.list[2].title1 = this.storeInfo.publication_time;
          this.list[3].title1 = this.storeInfo.kind;
          this.list[4].title1 = "是";
          this.list[5].title1 = this.skuData.isbn;
          this.list[6].title1 = this.storeInfo.format;
          this.list[7].title1 = this.storeInfo.paper_limie;
          this.list[8].title1 = this.storeInfo.packaging;
          this.good_type = this.storeInfo.good_type;
          if (this.storeInfo.good_type != 1) {
            this.getMeal();
          }
        })
        .catch(() => {
          this.$router.go(-1);
        })
        .finally(() => {
          this.detailSpinning = false;
        });
      addressList().then((res) => {
        if (res.data.data.list.length > 0) {
          this.address = res.data.data.list[0];
          this.addressId = res.data.data.list[0].id;
        }
      });
    },
    getMeal() {
      let url = this.good_type == 2 ? singleProductList : binningProductList;
      this.loading = true;
      url(this.id, {
        keyword: this.keywords,
      })
        .then((res) => {
          this.data = res.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addCart(num) {
      if (num == 1) {
        this.numberBox = true;
        return false;
      }
      addCart({
        productId: this.storeInfo.id,
        cartNum: this.minNumber,
        uniqueId: this.selected,
        is_new: num,
      }).then(() => {
        this.$message.success("加入成功");
        this.getCartNum();
        if (num == 0) {
          this.$store.dispatch("cart/getList", { page: 1, pageSize: 1000 });
          this.$store.dispatch("cart/getNum");
        }
      });
    },
    collect: throttle(function (userCollect) {
      if (!userCollect) {
        addCollect({
          id: this.storeInfo.id,
        }).then((res) => {
          this.$message.success(res.data.msg);
          this.storeInfo.userCollect = true;
        });
      } else {
        delCollect({
          id: this.storeInfo.id,
        }).then((res) => {
          this.$message.success(res.data.msg);
          this.storeInfo.userCollect = false;
        });
      }
    }, 2000),
    edit() {
      console.log(this.address);
      this.visible = true;
      this.form.real_name = this.address.real_name;
      this.form.phone = this.address.phone;
      this.form.province = this.address.province;
      this.form.province_id = this.address.province_id;
      this.form.city = this.address.city;
      this.form.city_id = this.address.city_id;
      this.form.district = this.address.district;
      this.form.district_id = this.address.district_id;
      this.form.detail = "";
      // this.options = [this.address.province_id,this.address.city_id,this.address.district_id]
    },
    handleCancel() {
      this.visible = false;
    },
    onChange(value, selectedOptio) {
      this.value = value;
      this.form.province = selectedOptio[0].n;
      this.form.city = selectedOptio[1].n;
      this.form.city_id = selectedOptio[1].v;
      this.form.district = selectedOptio[2].n;
      this.form.district_id = selectedOptio[2].v;
    },
    onSubmit() {
      if (this.value == undefined || this.value == "") {
        this.$message.warning("省市区不能为空");
        return;
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          this.form.id = this.addressId;
          addAddress(this.form)
            .then((res) => {
              this.$message.success(res.data.msg);
              this.value = undefined;
              this.$refs.ruleForm.resetFields();
              this.getData();
            })
            .finally(() => {
              this.visible = false;
              this.confirmLoading = false;
            });
        } else {
          console.log("请填写全信息");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "goodsDetail";
</style>
<style scoped>
.fixed {
  position: fixed;
  right: 32px;
  bottom: 60px;
  z-index: 3;
}

.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 497px;
  line-height: 497px;
  /* background: #364d79; */
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 35px;
  height: 35px;
  font-size: 35px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.2);
  opacity: 0.3;
}

.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}

.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
</style>
