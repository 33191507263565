import { postRequest, putRequest } from '@/utils/request'

//收藏商品列表
export const collectList = (params) => {
        return putRequest('collect/user', params)
    }
    // 添加收藏
export const addCollect = (params) => {
        return postRequest('collect/add', params)
    }
    // 取消收藏
export const delCollect = (params) => {
    return postRequest('collect/del', params)
}