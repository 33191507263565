// 函数节流
function throttle(fn, interval) {
    var enterTime = 0; //触发的时间
    var gapTime = interval; //间隔时间，如果interval不传，则默认2000ms
    return function (e) {
        var that = this;
        var backTime = new Date(); //第一次函数return即触发的时间
        if (backTime - enterTime > gapTime) {
            fn.call(that, e, arguments);
            enterTime = backTime; //赋值给第一次触发的时间，这样就保存了第二次触发的时间
        }
    };
}// 函数防抖
function debounce(fn, interval) {
    var timer;
    var gapTime = interval; //间隔时间，如果interval不传，则默认2000ms
    return function (e) {
        clearTimeout(timer);
        var that = this;
        var args = arguments; //保存此处的arguments，因为setTimeout是全局的，arguments不是防抖函数需要的。
        timer = setTimeout(function () {
            fn.call(that, e, args);
        }, gapTime);
    };
}
module.exports = {
    throttle: throttle,
    debounce: debounce
}