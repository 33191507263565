export const labelColor = [{
    color: "#ff5733",
    name: "红色",
    id: 1
}, {
    color: "#ff8d1a",
    name: "橙色",
    id: 2
}, {
    color: "#3ab887",
    name: "绿色",
    id: 3
}, {
    color: "#3c90f7",
    name: "蓝色",
    id: 4
}, {
    color: "#7948ea",
    name: "紫色",
    id: 5
}, {
    color: "#000000",
    name: "黑色",
    id: 6
}]